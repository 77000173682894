import styled from 'styled-components';

export const FocusVisible = styled.div`
  &.js-focus-visible :focus:not(.focus-visible) {
      outline: none;
  }
  &.js-focus-visible .focus-visible {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .5);
      outline: 0px !important;
  }
`;
