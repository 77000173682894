import React, { Suspense } from 'react';
// import PropTypes from 'prop-types';

import { Loading, NotFoundPage, FooterAdmin } from 'bumblebee-ui';
import { useSelector } from 'react-redux';
import { createBrowserHistory } from 'history';
import { Route, Routes, Navigate, Outlet } from 'react-router-dom';

import { getFeatureFlagsArray } from '../utils/helpers';

import NavBar from '../components/NavBar';

import {
  AppWrapper,
  CustomRouter,
  lazyWithRetry,
  PermissionRoute,
  PrivateRoute,
  PublicRoute,
  ServicersPrivateRoute,
} from './helpers';

const config = stateConfig;
const { applications } = config;
const featureFlags = getFeatureFlagsArray(applications);

// AUTH
const Register = lazyWithRetry(() => import('../components/auth/Register'));
const LogIn = lazyWithRetry(() => import('../components/auth/LogIn'));
const LogOut = lazyWithRetry(() => import('../components/auth/LogOut'));
const ForgotPassword = lazyWithRetry(() => import('../components/auth/ForgotPassword'));
const ResetPassword = lazyWithRetry(() => import('../components/auth/ResetPassword'));
const TwoFactor = lazyWithRetry(() => import('../components/auth/TwoFactor'));

// APPLICANTS
const Applicants = lazyWithRetry(() => import('../components/applicants/Applicants'));
const ApplicantDetail = lazyWithRetry(() => import('../components/applicants/ApplicantDetail'));
const RecordDetail = lazyWithRetry(() => import('../components/applicants/tabs/RecordDetail'));

// APPLICANT TABS
const ApplicantStatus = lazyWithRetry(() => import('../components/applicants/tabs/Status'));
const ApplicantCommunications = lazyWithRetry(() => import('../components/applicants/tabs/Communications'));
const ApplicantCounseling = lazyWithRetry(() => import('../components/applicants/tabs/Counseling'));
const ApplicantChecklists = lazyWithRetry(() => import('../components/applicants/tabs/Checklists'));
const ApplicantRecords = lazyWithRetry(() => import('../components/applicants/tabs/Records'));
const ApplicantLetters = lazyWithRetry(() => import('../components/applicants/tabs/Letters'));
const ApplicantPreScreen = lazyWithRetry(() => import('../components/applicants/tabs/PreScreen'));
const ApplicantApplicant = lazyWithRetry(() => import('../components/applicants/tabs/Applicant'));
const ApplicantCoApplicant = lazyWithRetry(() => import('../components/applicants/tabs/CoApplicant'));
const ApplicantHousehold = lazyWithRetry(() => import('../components/applicants/tabs/Household'));
const ApplicantLoan = lazyWithRetry(() => import('../components/applicants/tabs/Loan'));
const ApplicantAssistance = lazyWithRetry(() => import('../components/applicants/tabs/Assistance'));
const ApplicantFinancial = lazyWithRetry(() => import('../components/applicants/tabs/Financial'));
const ApplicantHardship = lazyWithRetry(() => import('../components/applicants/tabs/Hardship'));
const ApplicantDocuments = lazyWithRetry(() => import('../components/applicants/tabs/Documents'));
const ApplicantSignatures = lazyWithRetry(() => import('../components/applicants/tabs/Signatures'));
const ApplicantRecertification = lazyWithRetry(() => import('../components/applicants/tabs/Recertification'));

// REPORTS
const IndexReports = lazyWithRetry(() => import('../components/reports/IndexReports'));
const UploadCDF = lazyWithRetry(() => import('../components/reports/pages/UploadCDF'));
const SFTPUploadQueue = lazyWithRetry(() => import('../components/reports/pages/SFTPUploadQueue'));
const CDFGenerator = lazyWithRetry(() => import('../components/reports/pages/CDFGenerator'));
const BRecordGenerator = lazyWithRetry(() => import('../components/reports/pages/BRecordGenerator'));
const AccountingImport = lazyWithRetry(() => import('../components/reports/pages/AccountingImport'));
const ProcessedRecords = lazyWithRetry(() => import('../components/reports/pages/ProcessedRecords'));
const ProcessedERecords = lazyWithRetry(() => import('../components/reports/pages/ProcessedERecords'));
const HistoricalReports = lazyWithRetry(() => import('../components/reports/pages/HistoricalReports'));
const LWCImportExport = lazyWithRetry(() => import('../components/reports/pages/LWCImportExport'));
const ExecutiveOverview = lazyWithRetry(() => import('../components/reports/pages/ExecutiveOverview'));
const ApplicantReport = lazyWithRetry(() => import('../components/reports/pages/ApplicantReport'));
const TreasuryReport = lazyWithRetry(() => import('../components/reports/pages/TreasuryReport'));
const BRecordReport = lazyWithRetry(() => import('../components/reports/pages/BRecordReport'));
const ReservationReconciliation = lazyWithRetry(() => import('../components/reports/pages/ReservationReconciliation'));
const IQRecordReport = lazyWithRetry(() => import('../components/reports/pages/IQRecordReport'));
const ProcessorPerformance = lazyWithRetry(() => import('../components/reports/pages/ProcessorPerformance'));
const SSNReport = lazyWithRetry(() => import('../components/reports/pages/SSNReport'));
const FundingReport = lazyWithRetry(() => import('../components/reports/pages/FundingReport'));
const PaymentReconciliation = lazyWithRetry(() => import('../components/reports/pages/PaymentReconciliation'));
const ReportCharts = lazyWithRetry(() => import('../components/reports/pages/ReportCharts'));

// SETTINGS
const IndexSettings = lazyWithRetry(() => import('../components/settings/IndexSettings'));
const MySettings = lazyWithRetry(() => import('../components/settings/my-settings/MySettings'));
const ProfileInfo = lazyWithRetry(() => import('../components/settings/my-settings/ProfileInfo'));
const Sessions = lazyWithRetry(() => import('../components/settings/my-settings/Sessions'));
const TeamSettings = lazyWithRetry(() => import('../components/settings/team-settings/TeamSettings'));
const UserPermissionGroups = lazyWithRetry(() => import('../components/settings/team-settings/PermissionGroups'));

// SETTINGS / SYSTEM CONFIG
const SystemConfigIndex = lazyWithRetry(() => import('../components/settings/system-config/SystemConfigIndex'));
const ProcessorStatuses = lazyWithRetry(() => import('../components/settings/system-config/ProcessorStatuses'));
const Letters = lazyWithRetry(() => import('../components/settings/system-config/Letters'));
const ChecklistTemplates = lazyWithRetry(() => import('../components/settings/system-config/ChecklistTemplates'));
const AddChecklistTemplate = lazyWithRetry(() => import('../components/settings/system-config/AddChecklistTemplate'));
const EditChecklistTemplate = lazyWithRetry(() => import('../components/settings/system-config/EditChecklistTemplate'));
const CommunicationTemplates = lazyWithRetry(() => import('../components/settings/system-config/CommunicationTemplates'));
const StatusNoteTemplates = lazyWithRetry(() => import('../components/settings/system-config/StatusNoteTemplates'));
const DocumentNoteTemplates = lazyWithRetry(() => import('../components/settings/system-config/DocumentNoteTemplates'));
const AcceptingApplications = lazyWithRetry(() => import('../components/settings/system-config/AcceptingApplications'));
const Counties = lazyWithRetry(() => import('../components/settings/system-config/Counties'));
const AddCounty = lazyWithRetry(() => import('../components/settings/system-config/AddCounty'));
const EditCounty = lazyWithRetry(() => import('../components/settings/system-config/EditCounty'));
const Programs = lazyWithRetry(() => import('../components/settings/system-config/Programs'));
const Recertification = lazyWithRetry(() => import('../components/settings/system-config/Recertification'));
const AFRConfig = lazyWithRetry(() => import('../components/settings/system-config/AFR'));
const FlagsConfig = lazyWithRetry(() => import('../components/settings/system-config/Flags'));
const ProhibitedApplicants = lazyWithRetry(() => import('../components/settings/system-config/ProhibitedApplicants'));
const ESignConfig = lazyWithRetry(() => import('../components/settings/system-config/ESign'));
const ReferralCodes = lazyWithRetry(() => import('../components/settings/system-config/ReferralCodes'));
const StaffingGroups = lazyWithRetry(() => import('../components/settings/system-config/StaffingGroups'));
const Vendors = lazyWithRetry(() => import('../components/settings/system-config/Vendors'));
const AddEditVendor = lazyWithRetry(() => import('../components/settings/system-config/vendors-pages/AddEditVendor'));
const CounselingAgencies = lazyWithRetry(() => import('../components/settings/system-config/CounselingAgencies'));

// SETTINGS / SYSTEM CONFIG - DROPDOWN SECTIONS
const CounselingMilestones = lazyWithRetry(() => import('../components/settings/system-config/dropdown-options/CounselingMilestones'));
const CounselingTopics = lazyWithRetry(() => import('../components/settings/system-config/dropdown-options/CounselingTopics'));
const CensusTracts = lazyWithRetry(() => import('../components/settings/system-config/dropdown-options/CensusTracts'));
const EmploymentStatus = lazyWithRetry(() => import('../components/settings/system-config/dropdown-options/EmploymentStatus'));
const ExpenseCategories = lazyWithRetry(() => import('../components/settings/system-config/dropdown-options/ExpenseCategories'));
const HowdYouHear = lazyWithRetry(() => import('../components/settings/system-config/dropdown-options/HowdYouHear'));
const IncomeCategories = lazyWithRetry(() => import('../components/settings/system-config/dropdown-options/IncomeCategories'));
const MaritalStatus = lazyWithRetry(() => import('../components/settings/system-config/dropdown-options/MaritalStatus'));
const MortgageTypes = lazyWithRetry(() => import('../components/settings/system-config/dropdown-options/MortgageTypes'));
const PropertyTypes = lazyWithRetry(() => import('../components/settings/system-config/dropdown-options/PropertyTypes'));
const UserDenialReasons = lazyWithRetry(() => import('../components/settings/system-config/dropdown-options/UserDenialReasons'));
const UserWithdrawReasons = lazyWithRetry(() => import('../components/settings/system-config/dropdown-options/UserWithdrawReasons'));
const ZipCodes = lazyWithRetry(() => import('../components/settings/system-config/dropdown-options/ZipCodes'));

// SERVICERS AUTH
const ServicersLogIn = lazyWithRetry(() => import('../components/servicers/auth/LogIn'));
const ServicersForgotPassword = lazyWithRetry(() => import('../components/servicers/auth/ForgotPassword'));
const ServicersResetPassword = lazyWithRetry(() => import('../components/servicers/auth/ResetPassword'));
const ServicersTwoFactor = lazyWithRetry(() => import('../components/servicers/auth/TwoFactor'));
const ServicersRegister = lazyWithRetry(() => import('../components/servicers/auth/Register'));

// SERVICERS ACCOUNT
const ServicersDashboard = lazyWithRetry(() => import('../components/servicers/account/Dashboard'));
const Choose = lazyWithRetry(() => import('../components/servicers/account/tabs/ChooseRecord'));
const CreateRecord = lazyWithRetry(() => import('../components/servicers/account/tabs/CreateRecord'));

// SERVICER TABS
const ServicerUpload = lazyWithRetry(() => import('../components/servicers/account/tabs/Upload'));
const ServicerCreated = lazyWithRetry(() => import('../components/servicers/account/tabs/CreatedRecords'));
const ServicerDownload = lazyWithRetry(() => import('../components/servicers/account/tabs/Download'));
const ServicerAccount = lazyWithRetry(() => import('../components/servicers/account/tabs/Account'));
const ServicerHelp = lazyWithRetry(() => import('../components/servicers/account/tabs/Help'));

// TEST
const TestPage = lazyWithRetry(() => import('../components/TestPage'));

export const history = createBrowserHistory();

const AppRouter = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const permissions = useSelector((state) => state.auth.currentUser.permissions);
  const servicer_user = useSelector((state) => state.servicers.auth.currentUser);
  const isServicerLoggedIn = useSelector((state) => state.servicers.auth.isLoggedIn);

  const { pathname } = history.location;

  return (
    <CustomRouter history={history}>
      <div className="container-scroller">
        <NavBar
          isLoggedIn={isLoggedIn}
          isServicerLoggedIn={isServicerLoggedIn}
          onClickLogIn={() => history.push(pathname.includes('/servicers') ? '/servicers/login' : '/login')} // Bugged, does not recognize changes in pathname
          onClickLogOut={() => history.push(pathname.includes('/servicers') ? '/servicers/logout' : '/logout')}
        />

        <AppWrapper>
          <Suspense fallback={<Loading main={true} />}>
            <Routes>
              {/* AUTH */}
              <Route path={'/'} element={<PublicRoute><LogIn /></PublicRoute>} />
              <Route path={'/login'} element={<PublicRoute><LogIn /></PublicRoute>} />
              <Route path={'/logout'} element={<LogOut />} />
              <Route path={'/forgot-password'} element={<PublicRoute><ForgotPassword /></PublicRoute>} />
              <Route path={'/reset-password/:token?'} element={<PublicRoute><ResetPassword /></PublicRoute>} />
              <Route path={'/two-factor/:token?'} element={<PublicRoute><TwoFactor /></PublicRoute>} />

              {/* SIGN_UP - without token always reject */}
              <Route path={'/register/:token'} element={<PublicRoute><Register /></PublicRoute>} />

              {/* APPLICANTS */}
              <Route path={'/applicants'} element={<PermissionRoute perform={['applicant:read-all', 'applicant:read-group', 'applicant:read-assigned']}><Applicants /></PermissionRoute>} />
              <Route
                path={'/applicants/:applicant_uuid'}
                element={<PermissionRoute perform={['applicant:read-all', 'applicant:read-group', 'applicant:read-assigned']}><ApplicantDetail /></PermissionRoute>}
              >
                <Route index element={<Navigate to='status' replace />} />
                <Route path={'status'} element={<PermissionRoute
                  feature='status'
                  featureFlags={featureFlags}
                  permissions={permissions}
                  perform={['applicant:read-all', 'applicant:read-group', 'applicant:read-assigned']}><ApplicantStatus /></PermissionRoute>}
                />
                <Route path={'communications'} element={<PermissionRoute
                  feature='communications'
                  featureFlags={featureFlags}
                  permissions={permissions}
                  perform='applicant-message:read'><ApplicantCommunications /></PermissionRoute>}
                />
                <Route path={'counseling'} element={<PermissionRoute
                  feature='counseling'
                  featureFlags={featureFlags}
                  permissions={permissions}
                  perform='applicant-counseling:read'><ApplicantCounseling /></PermissionRoute>}
                />
                <Route path={'checklists'} element={<PermissionRoute
                  feature='checklists'
                  featureFlags={featureFlags}
                  permissions={permissions}
                  perform='applicant-checklist:read'><ApplicantChecklists /></PermissionRoute>}
                />
                <Route path={'records'} element={<PermissionRoute
                  feature='records'
                  featureFlags={featureFlags}
                  permissions={permissions}
                  perform='applicant-record:read'><ApplicantRecords /></PermissionRoute>}
                />
                <Route path={'letters'} element={<PermissionRoute
                  feature='letters'
                  featureFlags={featureFlags}
                  permissions={permissions}
                  perform='applicant-letter:read'><ApplicantLetters /></PermissionRoute>}
                />
                <Route path={'recertification'} element={<PermissionRoute
                  feature='recertification'
                  featureFlags={featureFlags}
                  permissions={permissions}
                  perform='applicant-recertification:read'><ApplicantRecertification /></PermissionRoute>}
                />
                <Route path={'pre-screen'} element={<PermissionRoute
                  feature='prescreen'
                  featureFlags={featureFlags}
                  permissions={permissions}
                  perform={['applicant:read-all', 'applicant:read-group', 'applicant:read-assigned']}><ApplicantPreScreen /></PermissionRoute>}
                />
                <Route path={'applicant'} element={<PermissionRoute
                  feature='applicant'
                  featureFlags={featureFlags}
                  permissions={permissions}
                  perform={['applicant:read-all', 'applicant:read-group', 'applicant:read-assigned']}><ApplicantApplicant /></PermissionRoute>}
                />
                <Route path={'co-applicant'} element={<PermissionRoute
                  feature='co-applicant'
                  featureFlags={featureFlags}
                  permissions={permissions}
                  perform={['applicant:read-all', 'applicant:read-group', 'applicant:read-assigned']}><ApplicantCoApplicant /></PermissionRoute>}
                />
                <Route path={'household'} element={<PermissionRoute
                  feature='household'
                  featureFlags={featureFlags}
                  permissions={permissions}
                  perform={['applicant:read-all', 'applicant:read-group', 'applicant:read-assigned']}><ApplicantHousehold /></PermissionRoute>}
                />
                <Route path={'loan'} element={<PermissionRoute
                  feature='loan'
                  featureFlags={featureFlags}
                  permissions={permissions}
                  perform={['applicant:read-all', 'applicant:read-group', 'applicant:read-assigned']}><ApplicantLoan /></PermissionRoute>}
                />
                <Route path={'assistance'} element={<PermissionRoute
                  feature='assistance'
                  featureFlags={featureFlags}
                  permissions={permissions}
                  perform={['applicant:read-all', 'applicant:read-group', 'applicant:read-assigned']}><ApplicantAssistance /></PermissionRoute>}
                />
                <Route path={'financial'} element={<PermissionRoute
                  feature='financial'
                  featureFlags={featureFlags}
                  permissions={permissions}
                  perform={['applicant:read-all', 'applicant:read-group', 'applicant:read-assigned']}><ApplicantFinancial /></PermissionRoute>}
                />
                <Route path={'hardship'} element={<PermissionRoute
                  feature='hardship'
                  featureFlags={featureFlags}
                  permissions={permissions}
                  perform={['applicant:read-all', 'applicant:read-group', 'applicant:read-assigned']}><ApplicantHardship /></PermissionRoute>}
                />
                <Route path={'documents'} element={<PermissionRoute
                  feature='documents'
                  featureFlags={featureFlags}
                  permissions={permissions}
                  perform={['applicant:read-all', 'applicant:read-group', 'applicant:read-assigned']}><ApplicantDocuments /></PermissionRoute>}
                />
                <Route path={'signatures'} element={<PermissionRoute feature='signatures'
                  featureFlags={featureFlags}
                  permissions={permissions}
                  perform='applicant-signature:read'><ApplicantSignatures /></PermissionRoute>}
                />
                <Route path={'records/:record_uuid/record-detail'} element={<PermissionRoute feature='records'
                  featureFlags={featureFlags}
                  permissions={permissions}
                  perform={'applicant-record:read'}><RecordDetail /></PermissionRoute>}
                />
              </Route>

              {/* REPORTS */}
              <Route path={'/reports'} element={<PermissionRoute perform={'report:read'}><IndexReports /></PermissionRoute>} />
              <Route path={'/reports/upload'} element={<PermissionRoute perform={'report-cdf:upload'}><UploadCDF /></PermissionRoute>} />
              <Route path={'/reports/sftp-upload-queue'} element={<PermissionRoute perform={'report-cdf:upload'}><SFTPUploadQueue /></PermissionRoute>} />
              <Route path={'/reports/cdf-generator'} element={<PermissionRoute perform={'report-cdf:create'}><CDFGenerator /></PermissionRoute>} />
              <Route path={'/reports/b-record-generator'} element={<PermissionRoute perform={'report-brecord:create'}><BRecordGenerator /></PermissionRoute>} />
              <Route path={'/reports/accounting'} element={<PermissionRoute perform={'report-accounting:upload'}><AccountingImport /></PermissionRoute>} />
              <Route path={'/reports/processed-record'} element={<PermissionRoute perform={'report-cdf:export'}><ProcessedRecords /></PermissionRoute>} />
              <Route path={'/reports/processed-e-record'} element={<PermissionRoute perform={'report-cdf:export'}><ProcessedERecords /></PermissionRoute>} />
              <Route path={'/reports/historical-reports'} element={<PermissionRoute perform={'report-cdf:download'}><HistoricalReports /></PermissionRoute>} />
              <Route path={'/reports/lwc-import-export'} element={<PermissionRoute perform={'lwc-data:read'}><LWCImportExport /></PermissionRoute>} />
              <Route path={'/reports/executive-overview-reports'} element={<PermissionRoute perform={'report-executive:download'}><ExecutiveOverview /></PermissionRoute>} />
              <Route path={'/reports/applicant-report'} element={<PermissionRoute perform={'report-applicant:download'}><ApplicantReport /></PermissionRoute>} />
              <Route path={'/reports/treasury-report'} element={<PermissionRoute perform={'report-treasury:download'}><TreasuryReport /></PermissionRoute>} />
              <Route path={'/reports/report-charts'} element={<PermissionRoute perform={'report-charts:read'}><ReportCharts /></PermissionRoute>} />
              <Route path={'/reports/b-record'} element={<PermissionRoute perform={'report-brecord:read'}><BRecordReport /></PermissionRoute>} />
              <Route path={'/reports/reservation-reconciliation'} element={<PermissionRoute perform={'report-reservation-reconciliation:read'}><ReservationReconciliation /></PermissionRoute>} />
              <Route path={'/reports/iq-record'} element={<PermissionRoute perform={'report-iqrecord:read'}><IQRecordReport /></PermissionRoute>} />
              <Route path={'/reports/payment-reconciliation'} element={<PermissionRoute perform={'report-payment-reconciliation:read'}><PaymentReconciliation /></PermissionRoute>} />
              <Route path={'/reports/processor-performance'} element={<PermissionRoute perform={'report-processor-performance:read'}><ProcessorPerformance /></PermissionRoute>} />
              {config.reports.showSSNReport && <Route path={'/reports/ssn'} element={<PermissionRoute perform={'report-ssn:read'}><SSNReport /></PermissionRoute>} />}
              {config.reports.showFundingReport && <Route path={'/reports/funding'} element={<PermissionRoute perform={'report-funding:download'}><FundingReport /></PermissionRoute>} />}

              {/* SETTINGS */}
              <Route
                path='/settings'
                element={<PrivateRoute><IndexSettings /></PrivateRoute>}
              >
                <Route index element={<Navigate to='my-settings' replace />} />
                <Route path='my-settings' element={<MySettings />} />
                <Route path='team-settings' element={<TeamSettings />} />
                <Route path='system-config' element={<PermissionRoute perform={'config:read'}><SystemConfigIndex /></PermissionRoute>} />
              </Route>
              <Route path='/settings/my-settings/profile-info' element={<PrivateRoute><ProfileInfo /></PrivateRoute>} />
              <Route path='/settings/my-settings/sessions' element={<PrivateRoute><Sessions /></PrivateRoute>} />
              <Route path='/settings/team-settings/permissions' element={<PermissionRoute perform={'permission:edit'}><UserPermissionGroups /></PermissionRoute>} />

              {/* SETTINGS - SYSTEM CONFIG */}
              <Route
                path='/settings/system-config'
                element={<PermissionRoute perform={'config:read'}><Outlet /></PermissionRoute>}
              >
                <Route path='/settings/system-config/processor-statuses' element={<ProcessorStatuses />} />
                <Route path='/settings/system-config/letters' element={<Letters />} />
                <Route path='/settings/system-config/checklists' element={<ChecklistTemplates />} />
                <Route path='/settings/system-config/checklists/add' element={<PermissionRoute perform={'config-checklist:edit'}><AddChecklistTemplate /></PermissionRoute>} />
                <Route path='/settings/system-config/checklists/edit/:checklist_uuid' element={<PermissionRoute perform={'config-checklist:edit'}><EditChecklistTemplate /></PermissionRoute>} />
                <Route path='/settings/system-config/communication-templates' element={<CommunicationTemplates />} />
                <Route path='/settings/system-config/status-note-templates' element={<StatusNoteTemplates />} />
                <Route path='/settings/system-config/document-note-templates' element={<DocumentNoteTemplates />} />
                <Route path='/settings/system-config/accepting-applications' element={<AcceptingApplications />} />
                <Route path='/settings/system-config/counties' element={<Counties />} />
                <Route path='/settings/system-config/counties/add' element={<PermissionRoute perform={'config-county:edit'}><AddCounty /></PermissionRoute>} />
                <Route path='/settings/system-config/counties/edit/:county_uuid' element={<PermissionRoute perform={'config-county:edit'}><EditCounty /></PermissionRoute>} />
                <Route path='/settings/system-config/programs' element={<Programs />} />
                <Route path='/settings/system-config/recertification' element={<Recertification />} />
                <Route path='/settings/system-config/afr' element={<AFRConfig />} />
                <Route path='/settings/system-config/flags' element={<FlagsConfig />} />
                <Route path='/settings/system-config/prohibited-applicants' element={<ProhibitedApplicants />} />
                <Route path='/settings/system-config/e-sign' element={<ESignConfig />} />
                <Route path='/settings/system-config/referral-codes' element={<ReferralCodes />} />
                <Route path='/settings/system-config/staffing-groups' element={<StaffingGroups />} />
                <Route path='/settings/system-config/vendors' element={<Vendors />} />
                <Route path='/settings/system-config/vendors/add' element={<PermissionRoute perform={'config-vendor:edit'}><AddEditVendor /></PermissionRoute>} />
                <Route path='/settings/system-config/vendors/:vendor_uuid' element={<PermissionRoute perform={'config:read'}><AddEditVendor /></PermissionRoute>} />
                <Route path='/settings/system-config/counseling-agencies' element={<CounselingAgencies />} />

                {/* SETTINGS / SYSTEM CONFIG - DROPDOWN OPTIONS */}
                {config.applications.counselingSection.isVisible && <Route path='/settings/system-config/counseling-topics' element={<CounselingTopics />} />}
                {config.applications.counselingSection.isVisible && <Route path='/settings/system-config/counseling-milestones' element={<CounselingMilestones />} />}
                <Route path='/settings/system-config/census-tracts/:type' element={<CensusTracts />} />
                <Route path='/settings/system-config/employment-status' element={<EmploymentStatus />} />
                <Route path='/settings/system-config/expense-categories' element={<ExpenseCategories />} />
                <Route path='/settings/system-config/howd-you-hear' element={<HowdYouHear />} />
                <Route path='/settings/system-config/income-categories' element={<IncomeCategories />} />
                <Route path='/settings/system-config/marital-status' element={<MaritalStatus />} />
                <Route path='/settings/system-config/mortgage-types' element={<MortgageTypes />} />
                <Route path='/settings/system-config/property-types' element={<PropertyTypes />} />
                <Route path='/settings/system-config/user-denial-reasons' element={<UserDenialReasons />} />
                <Route path='/settings/system-config/user-withdraw-reasons' element={<UserWithdrawReasons />} />
                <Route path='/settings/system-config/zip-codes' element={<ZipCodes />} />
              </Route>



              {/* SERVICERS AUTH */}
              {sftpIsEnabled && <Route path={'/servicers/login'} element={<PublicRoute><ServicersLogIn /></PublicRoute>} />}

              {sftpIsEnabled && <Route path={'/servicers/logout'} element={<LogOut />} />}
              {sftpIsEnabled && <Route path={'/servicers/forgot-password'} element={<PublicRoute><ServicersForgotPassword /></PublicRoute>} />}
              {sftpIsEnabled && <Route path={'/servicers/reset-password/:token?'} element={<PublicRoute><ServicersResetPassword /></PublicRoute>} />}
              {sftpIsEnabled && <Route path={'/servicers/two-factor/:token?'} element={<PublicRoute><ServicersTwoFactor /></PublicRoute>} />}
              {sftpIsEnabled && <Route path={'/servicers/register/:token'} element={<PublicRoute><ServicersRegister /></PublicRoute>} />}

              {/* SERVICERS ACCOUNT */}
              {sftpIsEnabled &&
                <Route path={'/servicers/dashboard'} element={<ServicersPrivateRoute><ServicersDashboard /></ServicersPrivateRoute>}>
                  <Route index element={<Navigate to='account' replace />} />
                  <Route path='account' element={<ServicerAccount />} />
                  <Route path='help' element={<ServicerHelp />} />
                  {servicer_user.can_upload_cdf && <Route path='upload' element={<ServicerUpload />} />}
                  {servicer_user.can_create_cdf && <Route path='create' element={<ServicerCreated />} />}
                  {servicer_user.can_download_cdf && <Route path='download' element={<ServicerDownload />} />}
                  {servicer_user.can_create_cdf && <Route path='choose' element={<ServicersPrivateRoute><Choose /></ServicersPrivateRoute>} />}
                  {servicer_user.can_create_cdf && <Route path='choose/:record_type/:record_uuid' element={<ServicersPrivateRoute><CreateRecord /></ServicersPrivateRoute>} />}
                  <Route element={() => <NotFoundPage onClick={() => history.push('/')} />} />
                </Route>
              }
              {/* TEST */}
              {environment !== 'production' && <Route path='/test' element={<PublicRoute><TestPage /></PublicRoute>} />}

              <Route path='*' element={<NotFoundPage onClick={() => history.push('/')} />} />
            </Routes>
          </Suspense>

        </AppWrapper>
        <FooterAdmin config={config} />
        {environment !== 'production' && <div className='footer-version'>Version {VERSION}</div>}
      </div>
    </CustomRouter>
  );
};

AppRouter.propTypes = {
};

export default AppRouter;
