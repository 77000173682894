import React from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

const ServicersPrivateRoute = ({ redirectPath = '/servicers/login', children }) => {
  const location = useLocation();
  const isServicerLoggedIn = useSelector((state) => state.servicers.auth.isLoggedIn);

  if (!isServicerLoggedIn) return <Navigate to={redirectPath} replace state={{ from: location }}/>;
  return children;
};

ServicersPrivateRoute.propTypes = {
  redirectPath: PropTypes.string,
  children: PropTypes.element,
};

export default ServicersPrivateRoute;
