import { format } from 'date-fns';

// CLEAN DATE FORMAT
export const cleanDateFormat = (cdf, formatStr = 'MM/dd/yyyy') => {
  const isDate = (str) => {
    if (/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) {
      return true;
    }
    if (/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}./.test(str)) {
      return true;
    }
    if (/([0]?[1-9]|[1][0-2])[-|/]([0]?[1-9]|[1|2][0-9]|[3][0|1])[-|/]\d{4}/.test(str)) {
      return true;
    }
    return false;
  };

  let formatted = cdf;
  if (isDate(formatted)) {
    formatted = format(cdf, formatStr);
  }
  return formatted;
};

// GENERATE USER PRE-SIGN STATUS
export const generateUserPreSignStatus = (obj) => {
  let filter_status = 'Pre-Sign (Created)';
  if (obj.meter_applicant) {
    filter_status = 'Pre-Sign (Applicant)';
  }
  if (obj.meter_co_applicants) {
    filter_status = 'Pre-Sign (Co-Applicant)';
  }
  if (obj.meter_household) {
    filter_status = 'Pre-Sign (Household)';
  }
  if (obj.meter_loans) {
    filter_status = 'Pre-Sign (Loans)';
  }
  if (obj.meter_assistance_requests) {
    filter_status = 'Pre-Sign (Assistance)';
  }
  if (obj.meter_financials) {
    filter_status = 'Pre-Sign (Financials)';
  }
  if (obj.meter_hardship) {
    filter_status = 'Pre-Sign (Hardship)';
  }
  if (obj.meter_documents) {
    filter_status = 'Pre-Sign (Documents)';
  }
  if (obj.meter_signatures_sent) {
    filter_status = 'Pre-Sign (Signatures)';
  }
  return filter_status;
};


// GET FEATURE FLAGS FROM CONFIG
export const getFeatureFlagsArray = (configApplications) => {
  const featureFlags = [];

  if (!configApplications || Object.keys(configApplications) === 0) {
    return null;
  }

  // CHECK FLAGS
  // -- ADMIN
  if (configApplications.statusSection?.isVisible) featureFlags.push('status');
  if (configApplications.communicationSection?.isVisible) featureFlags.push('communications');
  if (configApplications.checklistSection?.isVisible) featureFlags.push('checklists');
  if (configApplications.recordSection?.isVisible) featureFlags.push('records');
  if (configApplications.letterSection?.isVisible) featureFlags.push('letters');
  if (configApplications.recertificationSection?.isVisible) featureFlags.push('recertification');
  if (configApplications.counselingSection?.isVisible) featureFlags.push('counseling');
  if (configApplications.prescreenSection?.isVisible) featureFlags.push('prescreen');
  // -- CLIENT
  if (configApplications.applicantSection?.isVisible) featureFlags.push('applicant');
  if (configApplications.coapplicantSection?.isVisible) featureFlags.push('co-applicant');
  if (configApplications.householdSection?.isVisible) featureFlags.push('household');
  if (configApplications.loanSection?.isVisible) featureFlags.push('loan');
  if (configApplications.assistanceSection?.isVisible) featureFlags.push('assistance');
  if (configApplications.financialSection?.isVisible) featureFlags.push('financial');
  if (configApplications.hardshipSection?.isVisible) featureFlags.push('hardship');
  if (configApplications.documentSection?.isVisible) featureFlags.push('documents');
  if (configApplications.signatureSection?.isVisible) featureFlags.push('signatures');
  return featureFlags;
};

// CONVERT HEXCODES - '#FFFFFF' to (255, 255, 255,)
export const hexToRGB = (hex) => {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `${r}, ${g}, ${b}`;
};

// Uses regex to verify that the provided value does not contain more than X decimal places
// (for currency or sometimes percentage inputs)
export const checkDecimalPlaces = (value, numOfDecimalPlaces = 2) => {
  const regexString = `^(\\d*\\.{0,1}\\d{0,${numOfDecimalPlaces}}$)`;
  const regex = new RegExp(regexString);
  return regex.test(value);
};
