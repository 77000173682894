import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Can } from 'bumblebee-ui';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

const config = stateConfig;

const NavBar = ({ isLoggedIn, isServicerLoggedIn, onClickLogOut, onClickLogIn }) => {
  const navigate = useNavigate();

  const [activeDropdown, setActiveDropdown] = useState(false);
  const permissions = useSelector((state) => state.auth.currentUser.permissions);
  const { pathname } = useLocation();

  const [, primaryPath] = pathname.split('/');

  return (
    <>
      <nav className='navbar col-12 p-0 fixed-top d-flex flex-row'>
        <div className='navbar-menu-wrapper d-flex align-items-center justify-content-between'>
          <Button
            ghost
            className='d-flex align-items-center h-100 px-3'
            onClick={() => navigate(pathname.includes('/servicers') ? '/servicers/login' : '/')}
          >
            <div className='mb-0 d-flex align-items-center'>
              <img data-cy='state-logo' src={config.style.logo} style={{ maxHeight: '60px', maxWidth: '280px' }} alt={`${config.programName} logo`} />
              <h5 className='text-primary mb-0 ps-3'>{pathname.includes('/servicers') ? 'Servicer Portal' : 'Processor Portal'}</h5>
            </div>
          </Button>
          <ul className='navbar-nav'>
            {/* SKIP NAVIGATION */}
            <Button ghost size='sm' className='skip-link fw-normal mx-3 sr-only p-0' data-cy='skip-nav' onClick={(e) => {
              e.preventDefault();
              const container = document.getElementById('app-wrapper');
              if (container) {
                container.tabIndex = -1;
                container.focus();
                setTimeout(() => container.removeAttribute('tabindex'), 1000);
              }
            }}>Skip Navigation</Button>
            {isLoggedIn &&
              <>
                {/* Applicants */}
                <Can
                  permissions={permissions}
                  perform={['applicant:read-all', 'applicant:read-group', 'applicant:read-assigned']}
                  yes={() => (
                    <li className={`nav-item d-none d-lg-flex justify-content-center ${primaryPath === 'applicants' ? 'active' : ''}`}>
                      <Button
                        ghost
                        className='px-0 px-lg-3 nav-link d-flex align-items-center justify-content-center'
                        onClick={() => navigate('/applicants')}
                        data-cy='applicants'
                      >
                        <div className={`border-bottom border-3 p-2 ${pathname.includes('/applicants') ? 'border-primary' : ''}`}>
                          <span className='fas fa-users me-2' />Applicants
                        </div>
                      </Button>
                    </li>)} />

                {/* Reports */}
                <Can
                  permissions={permissions}
                  perform={'report:read'}
                  yes={() => (
                    <li className={`nav-item d-none d-lg-flex justify-content-center ${primaryPath === 'reports' ? 'active' : ''}`}>
                      <Button
                        ghost
                        className='px-0 px-lg-3 nav-link pointer d-flex align-items-center justify-content-center'
                        onClick={() => navigate('/reports')}
                        data-cy='reports'
                      >
                        <div className={`border-bottom border-3 p-2 ${pathname.includes('/reports') ? 'border-primary' : ''}`}>
                          <span className='fas fa-file-alt me-2' />Reports
                        </div>
                      </Button>
                    </li>)} />

                {/* Settings */}
                <li className={`nav-item d-none d-lg-flex justify-content-center ${primaryPath === 'settings' ? 'active' : ''}`}>
                  <Button
                    ghost
                    className='px-0 px-lg-3 nav-link pointer d-flex align-items-center justify-content-center' onClick={() => navigate('/settings')}
                    data-cy='settings'
                  >
                    <div className={`border-bottom border-3 p-2 ${pathname.includes('/settings') ? 'border-primary' : ''}`}>
                      <span className='fas fa-cog me-2' />Settings
                    </div>
                  </Button>
                </li>

                {/* Settings */}
                <li className='nav-item d-none d-lg-flex justify-content-center'>
                  <Button
                    ghost
                    className='px-0 px-lg-3 nav-link pointer d-flex align-items-center justify-content-center' onClick={() => {
                      window.open('https://hotbsoftware.atlassian.net/servicedesk/customer/portal/1/group/3/create/4', '_blank');
                    }}
                    data-cy='settings'
                  >
                    <div className='border-bottom border-3 p-2'>
                      <span className='fas fa-circle-question me-2' />Help
                    </div>
                  </Button>
                </li>
              </>
            }

            {!isLoggedIn && !isServicerLoggedIn
              ? (
                <li className='nav-link d-none d-lg-flex'>
                  <Button variant='success' size='sm' className='btn px-3 mx-1 mx-sm-3' onClick={onClickLogIn} data-cy='login'>Login</Button>
                </li>
              ) : (
                <li className='nav-link d-none d-lg-flex'>
                  <Button outline size='sm' className='px-3 mx-1 mx-sm-3' onClick={onClickLogOut} data-cy='logout'>Logout</Button>
                </li>
              )}

            {/* TABLET/MOBILE */}
            <li className={`nav-item dropdown ${activeDropdown ? 'show selected' : ''} d-flex d-lg-none justify-content-center align-items-center`}>
              <div className='nav-link mobile-menu pointer' onClick={() => setActiveDropdown(!activeDropdown)}>
                <span className='nav-icon fas fa-bars' />
              </div>

              <div className={`navbar-dropdown dropdown-menu shadow fh-regular pointer ${activeDropdown ? 'show' : ''}`}>

                {isLoggedIn &&
                  <>
                    <div className='dropdown-item' onClick={() => {
                      setActiveDropdown(false); navigate('/applicants');
                    }}>
                      <Button size='sm' ghost className='d-flex align-items-center text-black fw-normal w-100 px-3 py-1'>
                        <span className='icon fas fa-users me-3' />Applicants
                      </Button>
                    </div>

                    <div className='dropdown-divider'></div>

                    <div className='dropdown-item' onClick={() => {
                      setActiveDropdown(false); navigate('/reports');
                    }}>
                      <Button size='sm' ghost className='d-flex align-items-center text-black fw-normal w-100 px-3 py-1'>
                        <span className='icon fas fa-file-alt me-3' />Reports
                      </Button>
                    </div>

                    <div className='dropdown-divider'></div>

                    <div className='dropdown-item' onClick={() => {
                      setActiveDropdown(false); navigate('/settings');
                    }}>
                      <Button size='sm' ghost className='d-flex align-items-center text-black fw-normal w-100 px-3 py-1'>
                        <span className='icon fas fa-cog me-3' />Settings
                      </Button>
                    </div>

                    <div className='dropdown-divider'></div>

                    <div className='dropdown-item' onClick={() => {
                      setActiveDropdown(false);
                      window.open('https://hotbsoftware.atlassian.net/servicedesk/customer/portal/1/group/3/create/4', '_blank');
                    }}>
                      <Button size='sm' ghost className='d-flex align-items-center text-black fw-normal w-100 px-3 py-1'>
                        <span className='icon fas fa-circle-question me-3' />Help
                      </Button>
                    </div>
                  </>
                }

                {/* LOG IN/OUT BUTTON */}
                {!isLoggedIn && !isServicerLoggedIn
                  ? (
                    <div
                      className='dropdown-item'
                      onClick={() => {
                        setActiveDropdown(false);
                        onClickLogIn();
                      }}
                    >
                      <Button variant='success' size='sm' className='w-100 px-3 m-2'>Log in</Button>
                    </div>
                  ) : (
                    <div
                      className='dropdown-item'
                      onClick={() => {
                        setActiveDropdown(false);
                        onClickLogOut();
                      }}
                    >
                      <Button size='sm' className='w-100 px-3 m-2'>Log out</Button>
                    </div>
                  )}

              </div>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

NavBar.propTypes = {
  isLoggedIn: PropTypes.bool,
  isServicerLoggedIn: PropTypes.bool,
  onClickLogOut: PropTypes.func,
  onClickLogIn: PropTypes.func,
};

export default NavBar;
