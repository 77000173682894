import { createSlice } from '@reduxjs/toolkit';
import ls from 'local-storage';
import { toast } from 'react-toastify';

import { apiSlice } from '../apiSlice';
import { history } from '../../routers/AppRouter';
import axios from '../../utils/axios';

const initialState = {
  currentUser: {},
  isLoggedIn: false,

  isAFR: false,
  afr_uuid: null,
};



const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    /* AUTH */
    login: (auth, action) => {
      ls('user', action.payload || {});
      auth.currentUser = action.payload || {};
      auth.isLoggedIn = true;
    },
    logout: () => { }, // triggers appReducer conditional, resets all state to initialState

    setCurrentApplication: (auth, action) => {
      auth.isAFR = action.payload.isAFR;
      auth.afr_uuid = action.payload.afr_uuid || null;
    },
  },
});



export const {
  /* AUTH */
  login,
  logout,

  setCurrentApplication,
} = slice.actions;


export default slice;



// Logout with cleanup
export const startLogout = (redirectPath = '/') => async (dispatch) => {
  ls.remove('user');
  ls.remove('page-has-been-force-refreshed');
  dispatch(apiSlice.util.resetApiState()); // Clear RTK query cache
  dispatch(logout()); // triggers appReducer conditional, resets all state to initialState
  history.push(redirectPath);
};



const tags = {};

const authApi = apiSlice
  .enhanceEndpoints({ addTagTypes: Object.values(tags) })
  .injectEndpoints({
    endpoints: (build) => ({

      // Login
      login: build.mutation({
        queryFn: async ({ data, deepLink, navigate }, api) => {
          try {
            const response = await axios({
              url: `${baseURL}/login`,
              method: 'POST',
              data,
            });
            const {
              user,
              // refresh_tos,
            } = response.data;
            await api.dispatch(login(user));

            deepLink
              ? navigate(deepLink)
              : navigate('/settings/my-settings');

            return { data: response.data };
          } catch (error) {
            let isToast = true;
            let toastMessage = 'It looks like something went wrong. Please try again.';

            if (error.response?.status === 409) {
              isToast = false;
              ls('email', data.email);

              deepLink
                ? navigate('/two-factor', { state: { deepLink } })
                : navigate('/two-factor');
            }

            if (error.response?.status === 423) {
              toastMessage = 'Too many failed login attempts.  Please try again later.';
              navigate('/');
            }

            if (isToast) toast.error(toastMessage);
            console.error(error.response);

            return { error: error.message };
          }
        },
      }),



      // Logout
      logout: build.mutation({
        queryFn: async (payload, api) => {
          try {
            const response = await axios({
              url: `${baseURL}/logout`,
              method: 'GET',
            });
            api.dispatch(startLogout());
            return { data: response.data };
          } catch (error) {
            api.dispatch(startLogout());
            console.error(error.response);
            toast.error('It looks like something went wrong.');
            return { error: error.message };
          }
        },
      }),



      // Get Register Token
      getRegisterToken: build.query({
        queryFn: async (token) => {
          try {
            const response = await axios({
              url: `${baseURL}/register/${token}`,
              method: 'GET',
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong. Please try again.');
            return { error: error.message };
          }
        },
      }),



      // Register
      register: build.mutation({
        queryFn: async (payload) => {
          try {
            const response = await axios({
              url: `${baseURL}/register`,
              method: 'POST',
              data: payload,
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            if (error.response?.status === 409) {
              ls('email', payload.email);
              history.push('/two-factor');
            } else {
              toast.error('It looks like something went wrong. Please try again.');
            }
            return { error: error.message };
          }
        },
      }),



      // Validate MFA
      validateMFA: build.mutation({
        queryFn: async ({ data, deepLink }, api) => {
          try {
            const response = await axios({
              url: `${baseURL}/validate/session`,
              method: 'POST',
              data,
            });

            if (response.data.user) {
              const {
                user,
                // refresh_tos,
              } = response.data;
              ls.remove('email');
              api.dispatch(login(user));

              history.push(deepLink ? deepLink : '/settings');
            } else {
              toast.warn('Something went wrong, Resend a new code');
            }

            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong. Please try again.');
            return { error: error.message };
          }
        },
      }),



      // Resend MFA Code
      resendMFACode: build.mutation({
        queryFn: async (email) => {
          try {
            const response = await axios({
              url: `${baseURL}/resend/mfa`,
              method: 'POST',
              data: { email },
            });
            toast.success('New Code Sent');
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong. Please try again.');
            return { error: error.message };
          }
        },
      }),



      // Send Forgot Password Email
      sendForgotPasswordEmail: build.mutation({
        queryFn: async ({ data, cb }) => {
          try {
            const response = await axios({
              url: `${baseURL}/reset-password-email`,
              method: 'POST',
              data,
            });
            cb();
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong. Please try again.');
            return { error: error.message };
          }
        },
      }),



      // Validate Password Reset Token
      validatePasswordResetToken: build.query({
        queryFn: async (token) => {
          try {
            const response = await axios({
              url: `${baseURL}/reset-password/${token}`,
              method: 'GET',
            });
            return { data: response.data.isValid };
          } catch (error) {
            console.error(error.response);
            return { error: error.message };
          }
        },
      }),



      // Reset Password
      resetPassword: build.mutation({
        queryFn: async (payload) => {
          try {
            const response = await axios({
              url: `${baseURL}/reset-password`,
              method: 'POST',
              data: payload,
            });
            toast.success('Password updated. Please log back in.');
            history.push('/logout');
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong. Please try again.');
            return { error: error.message };
          }
        },
      }),

    }),
  });



export const {
  useLoginMutation,
  useLogoutMutation,

  useGetRegisterTokenQuery,
  useRegisterMutation,

  useValidateMFAMutation,
  useResendMFACodeMutation,

  useSendForgotPasswordEmailMutation,
  useValidatePasswordResetTokenQuery,
  useResetPasswordMutation,
} = authApi;
