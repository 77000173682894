import React from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const PublicRoute = ({ redirectPath = '/settings', children }) => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const isServicerLoggedIn = useSelector((state) => state.servicers.auth.isLoggedIn);

  if (isLoggedIn) return <Navigate to={redirectPath} replace />;
  if (isServicerLoggedIn) return <Navigate to={'/servicers/dashboard/account'} replace />;
  return children;
};

PublicRoute.propTypes = {
  redirectPath: PropTypes.string,
  children: PropTypes.element,
};

export default PublicRoute;
