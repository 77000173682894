import { configureStore, combineSlices, combineReducers } from '@reduxjs/toolkit';

import { apiSlice } from './apiSlice';
import authSlice, { logout } from './reducers/auth';
import servicersAuthSlice, { logout as logoutServicers } from './reducers/servicers/servicersAuth';
import stickyChecklistsSlice from './reducers/stickyChecklists';
import stickyCommunications from './reducers/stickyCommunications';

export default () => {
  const servicersReducer = combineReducers({
    auth: servicersAuthSlice.reducer,
  });

  const appReducer = combineSlices(
    apiSlice,
    authSlice,
    stickyChecklistsSlice, // Sticky checklists
    stickyCommunications, // Sticky communications
    {
      reducerPath: 'servicers',
      reducer: servicersReducer,
    },
  );

  const rootReducer = (state, action) => {
    if (action.type === logout.type || action.type === logoutServicers.type) return appReducer(undefined, action); // reset to initial state on logout
    return appReducer(state, action);
  };

  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(apiSlice.middleware),
    devTools: environment !== 'production'
      ? {
        actionSanitizer: (action) => (action.payload ? { ...action, payload: '<<LONG_BLOB>>' } : action),
        // stateSanitizer: (state) => (state.payload ? { ...state, payload: '<<LONG_BLOB>>' } : state), // NOT RIGHT
      }
      : false,
  });
};
