import { createSlice } from '@reduxjs/toolkit';
import ls from 'local-storage';
import { toast } from 'react-toastify';

import { apiSlice } from '../../apiSlice';
import { history } from '../../../routers/AppRouter';
import axios from '../../../utils/axios';



const initialState = {
  currentUser: {},
  isLoggedIn: false,
};

const slice = createSlice({
  name: 'servicersAuth',
  initialState,
  reducers: {
    login: (servicersAuth, action) => {
      ls('user-servicer', action.payload || {});
      servicersAuth.currentUser = action.payload || {};
      servicersAuth.isLoggedIn = true;
    },
    logout: () => { }, // triggers appReducer conditional, resets all state to initialState
  },
});

export const {
  login,
  logout,
} = slice.actions;

export default slice;



// Logout with cleanup
export const startLogout = (redirectPath = '/servicers/login') => async (dispatch) => {
  ls.remove('user-servicer');
  ls.remove('page-has-been-force-refreshed');
  dispatch(apiSlice.util.resetApiState()); // Clear RTK query cache
  dispatch(logout()); // triggers appReducer conditional, resets all state to initialState
  history.push(redirectPath);
};


const tags = {};

const servicersAuthApi = apiSlice
  .enhanceEndpoints({ addTagTypes: Object.values(tags) })
  .injectEndpoints({
    endpoints: (build) => ({

      // Login
      loginServicer: build.mutation({
        queryFn: async ({ data, deepLink, navigate }, api) => {
          try {
            const response = await axios({
              url: `${servicerBaseURL}/login`,
              method: 'POST',
              data,
            });
            const {
              user,
              // refresh_tos,
            } = response.data;
            await api.dispatch(login(user));

            return { data: response.data };
          } catch (error) {
            let isToast = true;
            let toastMessage = 'It looks like something went wrong. Please try again.';

            if (error.response?.status === 409) {
              isToast = false;
              ls('email', data.email);

              deepLink
                ? navigate('/servicers/two-factor', { state: { deepLink } })
                : navigate('/servicers/two-factor');
            }

            if (error.response?.status === 423) {
              toastMessage = 'Too many failed login attempts.  Please try again later.';
              navigate('/servicers/login');
            }

            if (isToast) toast.error(toastMessage);
            console.error(error.response);

            return { error: error.message };
          }
        },
      }),



      // Logout
      logoutServicer: build.mutation({
        queryFn: async (payload, api) => {
          try {
            const response = await axios({
              url: `${servicerBaseURL}/logout`,
              method: 'GET',
            });

            api.dispatch(startLogout());
            return { data: response.data };
          } catch (error) {
            api.dispatch(startLogout());
            console.error(error.response);
            toast.error('It looks like something went wrong.');
            return { error: error.message };
          }
        },
      }),



      // Get Servicer Register Token
      getServicerRegisterToken: build.query({
        queryFn: async (token) => {
          try {
            const response = await axios({
              url: `${servicerBaseURL}/register/${token}`,
              method: 'GET',
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong. Please try again.');
            return { error: error.message };
          }
        },
      }),



      // Register
      registerServicer: build.mutation({
        queryFn: async (payload, api) => {
          try {
            const response = await axios({
              url: `${servicerBaseURL}/register`,
              method: 'POST',
              data: payload,
            });
            const { user } = response.data;
            await api.dispatch(login(user));
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            if (error.response?.status === 409) {
              ls('email', payload.email);
              history.push('/servicers/two-factor');
            } else {
              toast.error('It looks like something went wrong. Please try again.');
            }
            return { error: error.message };
          }
        },
      }),



      // Validate MFA
      validateMFAServicer: build.mutation({
        queryFn: async ({ data, deepLink }, api) => {
          try {
            const response = await axios({
              url: `${servicerBaseURL}/validate/session`,
              method: 'POST',
              data,
            });

            if (response.data.user) {
              const {
                user,
                // refresh_tos,
              } = response.data;
              ls.remove('email');
              api.dispatch(login(user));

              if (deepLink) history.push(deepLink);
            } else {
              toast.warn('Something went wrong, Resend a new code');
            }

            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong. Please try again.');
            return { error: error.message };
          }
        },
      }),



      // Resend MFA Code
      resendMFACodeServicer: build.mutation({
        queryFn: async (email) => {
          try {
            const response = await axios({
              url: `${servicerBaseURL}/resend/mfa`,
              method: 'POST',
              data: { email },
            });
            toast.success('New Code Sent');
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong. Please try again.');
            return { error: error.message };
          }
        },
      }),



      // Send Forgot Password Email
      sendForgotPasswordEmailServicer: build.mutation({
        queryFn: async ({ data, cb }) => {
          try {
            const response = await axios({
              url: `${servicerBaseURL}/reset-password-email`,
              method: 'POST',
              data,
            });
            cb();
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong. Please try again.');
            return { error: error.message };
          }
        },
      }),



      // // Validate Password Reset Token NOT USED?
      // validatePasswordResetTokenServicer: build.query({
      //   queryFn: async (token) => {
      //     try {
      //       const response = await axios({
      //         url: `${servicerBaseURL}/reset-password/${token}`,
      //         method: 'GET',
      //       });
      //       return { data: response.data.isValid };
      //     } catch (error) {
      //       console.error(error.response);
      //       return { error: error.message };
      //     }
      //   },
      // }),



      // Reset Password
      resetPasswordServicer: build.mutation({
        queryFn: async (payload) => {
          try {
            const response = await axios({
              url: `${servicerBaseURL}/reset-password`,
              method: 'POST',
              data: payload,
            });
            toast.success('Password updated. Please log back in.');
            history.push('/servicers/logout');
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong. Please try again.');
            return { error: error.message };
          }
        },
      }),

    }),
  });



export const {
  useLoginServicerMutation,
  useLogoutServicerMutation,

  useGetServicerRegisterTokenQuery,
  useRegisterServicerMutation,

  useValidateMFAServicerMutation,
  useResendMFACodeServicerMutation,

  useSendForgotPasswordEmailServicerMutation,
  useResetPasswordServicerMutation,
} = servicersAuthApi;
