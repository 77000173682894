import React from 'react';
import PropTypes from 'prop-types';

import { Can } from 'bumblebee-ui';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

const PermissionRoute = ({ perform = '', feature = '', featureFlags = null, redirectPath = '/login', children }) => {
  const location = useLocation();
  const permissions = useSelector((state) => state.auth.currentUser.permissions);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  if (!isLoggedIn) return <Navigate to={redirectPath} replace state={{ from: location }}/>;

  return <Can
    perform={perform}
    permissions={permissions}
    feature={feature}
    featureFlags={featureFlags}
    yes={() => children}
    no={() => <Navigate to={'/'} replace state={{ from: location }}/>}
  />;
};

PermissionRoute.propTypes = {
  perform: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
  ]),
  feature: PropTypes.string,
  featureFlags: PropTypes.array,
  redirectPath: PropTypes.string,
  children: PropTypes.element,
};

export default PermissionRoute;
