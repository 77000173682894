import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  checklist_sticky: {},
  is_sticky_checklist: false,
};

const slice = createSlice({
  name: 'stickyChecklists',
  initialState,
  reducers: {
    setChecklistSticky: (stickyChecklists, action) => {
      stickyChecklists.checklist_sticky = action.payload;
    },
    setStickyChecklist: (stickyChecklists, action) => {
      stickyChecklists.is_sticky_checklist = action.payload;
    },
  },
});



export const {
  setChecklistSticky,
  setStickyChecklist,
} = slice.actions;



export default slice;
