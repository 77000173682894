import axios from 'axios';
import ls from 'local-storage';
import { toast } from 'react-toastify';

import { store } from '../app';
import { history } from '../routers/AppRouter';

// CREATE INSTANCE
const axiosInstance = axios.create();
axiosInstance.defaults.withCredentials = true;


// RESPONSE INTERCEPTOR
axiosInstance.interceptors.response.use((response) => response, (error) => {
  const { pathname } = history.location;
  const { status } = error.response;

  if (status === 401) {
    if (pathname.includes('/servicers')) {
      const isServicerLoggedIn = store.getState().servicers.auth.isLoggedIn;
      ls.remove('user-servicer');
      if (isServicerLoggedIn) toast.warn('Something is wrong. Please log back in.');
    } else {
      const { isLoggedIn } = store.getState().auth;
      if (isLoggedIn) toast.warn('Something is wrong. Please log back in.');
      ls.remove('user');
    }
    ls.remove('page-has-been-force-refreshed');
    // Don't redirect to logout page if the error comes from the logout route.
    if (!error.request.responseURL.includes('/logout')) {
      history.push(pathname.includes('/servicers') ? '/servicers/logout' : '/logout');
    }
  }

  // The API will return a 403 if an Admin tries to access an Applicant which they do not have permission to access
  if (status === 403 && error.response.data.message === 'Applicant Inaccessible') {
    history.push('/not-found');
  }

  if (status === 498) {
    const originalRequest = error.config;
    return new Promise((resolve, reject) => {
      // Hit the /refresh/access-token route for new accessToken
      axiosInstance.get(`${pathname.includes('/servicers') ? servicerBaseURL : baseURL}/refresh/access-token`)
        .then(() => {
          axios(originalRequest)
            .then((respo) => resolve(respo))
            .catch((errr) => {
              if (errr.response.status === 401) {
                if (pathname.includes('/servicers')) {
                  const isServicerLoggedIn = store.getState().servicers.auth.isLoggedIn;
                  if (isServicerLoggedIn) toast.warn('Something is wrong. Please log back in.');
                  ls.remove('user-servicer');
                } else {
                  const { isLoggedIn } = store.getState().auth;
                  if (isLoggedIn) toast.warn('Something is wrong. Please log back in.');
                  ls.remove('user');
                }
                ls.remove('page-has-been-force-refreshed');
                history.push(pathname.includes('/servicers') ? '/servicers/logout' : '/logout');
              }
            });
        })
        .catch((err) => {
          console.error(err);
          return reject(err);
        });
    });
  }

  return Promise.reject(error);
});


export default axiosInstance;
