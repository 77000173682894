import React from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

const PrivateRoute = ({ redirectPath = '/login', children }) => {
  const location = useLocation();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  if (!isLoggedIn) return <Navigate to={redirectPath} replace state={{ from: location }}/>;
  return children;
};

PrivateRoute.propTypes = {
  redirectPath: PropTypes.string,
  children: PropTypes.element,
};

export default PrivateRoute;
